<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <!-- 급유/배유 내역 조회 --------------------------------------------------------------------------------------------->

    <BCard header-tag="header" footer-tag="footer">

      <div slot="header">
        <BIconServer/> <strong> 급유/배유, 소모처리 현황</strong>
        <div class="card-header-actions">
          <small class="text-muted">항공기 급유/배유 정보를 관리하고 항공기 소모데이터를 전송 합니다</small>
        </div>
      </div>

      <BTabs>
        <BTab title="급유/배유 현황">
          <BRow class="mt-1 mb-1">
            <BCol sm="2">
              <BInputGroup size="sm">
                <BFormSelect size="sm" v-model="tid" :options="tankOpts" @input="getInoutData" style="background-color:#2f303a"/>
                <BInputGroupAppend>
                  <BFormSelect size="sm" v-model="sts" :options="FuelStsOpts" style="background-color:#2f303a"
                               @input="getInoutData"/>
                </BInputGroupAppend>


              </BInputGroup>
            </BCol>
            <BCol sm="3">

              <BInputGroup size="sm">
                <BFormSelect size="sm" v-model="volUnit" :options="con.unitVolOpts" style="background-color:#2f303a" @input="getInoutData"/>
                <BFormSelect size="sm" v-model="lenUnit" :options="con.unitLenOpts" style="background-color:#2f303a" @input="getInoutData"/>
                <BFormSelect size="sm" v-model="tmpUnit" :options="con.unitTmpOpts" style="background-color:#2f303a" @input="getInoutData"/>
              </BInputGroup>
            </BCol>
            <BCol>
              <BInputGroup size="sm">
                <BFormInput type="date" v-model="fromDts" @input="getInoutData"></BFormInput>
                <BFormInput type="date" v-model="toDts" @input="getInoutData"></BFormInput>
              </BInputGroup>
            </BCol>
            <BCol sm="2" class="text-right">
              <BInputGroup size="sm">
                <BFormSelect size="sm" v-model="$store.state.numOfInoutItem" :options="[10,15,20,30,50,100]" style="background-color:#2f303a"/>
                <BInputGroupAppend>
                  <BButtonGroup>
                    <BButton size="sm" variant="primary" @click="getInoutData"><BIconArrowRepeat/></BButton>
                  </BButtonGroup>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>
          </BRow>

          <BRow>
            <BCol v-if="rows.length">
              <vue-excel-editor
                v-model="rows"
                ref="excelGrid"
                width="100%"
                class="mb-1"
                :page="$store.state.numOfInoutItem"
                :readonly-style="{backgroundColor:'#4F5040'}"
                @select="selectRecord"
                @update="updateCell"
                :localized-label="editorLabel"
                :key="editorKey"
                filter-row>
                <vue-excel-column field="_id"          type="string"   key-field invisible/>
                <vue-excel-column field="bgDt"         type="string"   width="150px" :label="cols['bgDt']" :to-text="toLocalTime" readonly/>
                <vue-excel-column field="edDt"         type="string"   width="150px" :label="cols['edDt']" :to-text="toLocalTime" readonly/>
                <vue-excel-column field="tcd"          type="string"   width="100px" label="탱크코드" readonly/>
                <vue-excel-column field="tnm"          type="string"   width="130px" label="급유차명" readonly/>
                <vue-excel-column field="eqnm"          type="string"   width="110px" :label="cols['eqnm']" readonly/>
                <vue-excel-column field="eqid"          type="string"   width="110px" :label="cols['eqid']" readonly/>
                <vue-excel-column field="ano"          type="string"   width="120px" :label="cols['ano']"/>
                <vue-excel-column field="sts"          type="map"     width="100px" label="급유/배유" :options="IoStsMap" readonly/>
                <!--            <vue-excel-column field="targetVol"    type="number"  width="80px" :label="cols['targetVol']" :to-text="toCommaInt" readonly/>-->
                <!--                <vue-excel-column field="tid"        type="string"   width="80px" :label="cols['tid']" readonly/>-->
                <!--                <vue-excel-column field="use"        type="string"   width="80px" :label="cols['use']" readonly/>-->
                <vue-excel-column field="isDone"       type="map"      width="100px" :label="cols['isDone']" :options="{true:'완료', false:'작업중'}" readonly/>
                <vue-excel-column field="bgOhr"        type="number"   width="120px" :label="`${cols['bgOhr']}_${unitSign(lenUnit)}`" :to-text="lenUnit==='i'?toCommaFix2:toCommaInt" readonly/>
                <vue-excel-column field="bgOvm"        type="number"   width="120px" :label="`${cols['bgOvm']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>

                <!--            <vue-excel-column field="bgOvc"        type="number"   width="80px" :label="cols['bgOvc']" :to-text="toCommaInt" readonly/>-->
                <vue-excel-column field="bgAvgTm"      type="number"   width="120px" :label="`${cols['bgAvgTm']}_${unitSign(tmpUnit)}`" readonly/>

                <vue-excel-column field="adjOv"        type="number"   width="100px" :label="`${cols['adjOv']}_${unitSign(volUnit)}`"/>
                <vue-excel-column field="inOvm"        type="number"   width="100px" :label="`${cols['inOvm']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
                <vue-excel-column field="outOvm"        type="number"   width="100px" :label="`${cols['outOvm']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>

                <vue-excel-column field="adjRemark"    type="string"   width="120px" :label="cols['adjRemark']"/>
                <vue-excel-column field="opRemark"     type="string"   width="120px" :label="cols['opRemark']"/>

                <vue-excel-column field="edOhr"        type="number"   width="120px" :label="`${cols['edOhr']}_${unitSign(lenUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
                <vue-excel-column field="edOvm"        type="number"   width="120px" :label="`${cols['edOvm']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
                <vue-excel-column field="edOvc"        type="number"   width="120px" :label="`${cols['edOvc']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
                <vue-excel-column field="edAvgTm"      type="number"   width="120px" :label="`${cols['edAvgTm']}_${unitSign(tmpUnit)}`" readonly/>

                <vue-excel-column field="diffOhr"      type="number"   width="120px" :label="`${cols['diffOhr']}_${unitSign(lenUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>
                <vue-excel-column field="diffOvm"      type="number"   width="120px" :label="`${cols['diffOvm']}_${unitSign(volUnit)}`" :to-text="volUnit==='g'?toCommaFix2:toCommaInt" readonly/>

                <!--            <vue-excel-column field="diffOvc"      type="number"   width="80px" :label="cols['diffOvc']" :to-text="toCommaInt" readonly/>-->



                <!--            <vue-excel-column field="inOvc"        type="number"   width="80px" :label="cols['inOvc']" :to-text="toCommaInt" readonly/>-->

                <vue-excel-column field="createdAt"      type="string"   width="160px" label="등록일시" :to-text="toLocalTime" readonly/>
                <vue-excel-column field="batchDt"      type="string"   width="140px" label="전송일시" :to-text="toLocalYmd" readonly/>
                <vue-excel-column field="updId"        type="string"   width="100px" label="수정자"/>



              </vue-excel-editor>


            </BCol>
          </BRow>

          <BRow class="mt-2">
            <BCol>
              <BButtonGroup>
                <BButton variant="primary"
                         @click="showFuelingForm"
                         class="font-weight-bold">
                  <BIconPlusCircleFill/> 급유정보 등록
                </BButton>

                <BButton variant="danger"
                         v-if="row!==null"
                         @click="deleteFueling"
                         class="ml-2 font-weight-bold">
                  <BIconTrash2Fill/> 선택정보 삭제
                </BButton>

              </BButtonGroup>
            </BCol>
            <BCol class="text-right">
              <BBadge variant="info">신규</BBadge> {{ selectedInfo.normal }}
              <BBadge varinat="danger">중복</BBadge> {{ selectedInfo.already }}
              <BBadge variant="dark">제외</BBadge> {{ selectedInfo.exclude }}

                <BButton variant="warning"
                         class="ml-2 font-weight-bold text-dark"
                         :disabled="selectedRows.length===0"
                         @click="setConsumption">
                  <BIconUpload/> 소모처리 등록
                </BButton>
            </BCol>
          </BRow>


          <BCard class="mt-2" v-if="row">
            <BRow>
              <BCol>
                <BBadge variant="info">급유차</BBadge> <BBadge variant="dark" class="mr-3">{{row?.tnm}}</BBadge>
                <BBadge variant="info">기간</BBadge> <BBadge variant="dark" class="mr-3">{{toLocalTime(row?.bgDt)}} ~ {{ toLocalTime(row?.edDt) }}</BBadge>
                <BBadge :variant="(row?.sts==='I')?'danger':'primary'">{{(row?.sts==='R')?'급유량':'배유량'}}</BBadge> <BBadge variant="dark">{{(row?.sts==='I')? toCommaInt(row?.inOvm): toCommaInt(row?.outOvm)}}</BBadge>
                <small class="ml-5 text-muted"> 그래프는 시작전/후 10분이 더 표시됩니다.</small>

              </BCol>
            </BRow>

            <BRow>
              <BCol>
                <GraphInout :tid="row.tid"
                            :fromDts="row.bgDt"
                            :toDts="row.edDt"
                            :volUnit="volUnit"
                            :graphKey="graphKey"

                />
              </BCol>
            </BRow>
          </BCard>

          <CRow>
            <CCol col="6">
              <CCard class="mt-2 border-primary" v-if="isShowFuelingForm">
                <CCardHeader>
                  <BIconArchiveFill/> <strong> 급유등록</strong>
                </CCardHeader>

                <CCardBody>

                  <BInputGroup prepend="급유 일자" size="sm">
                    <BFormInput type="date" v-model="fuel.edDt"/>
                  </BInputGroup>

                  <BInputGroup prepend="급유차 선택" size="sm" class="mt-1">
                    <BFormSelect size="sm"
                                 v-model="fuel.tid"
                                 :options="fuelTankOpts"
                                 @input="selectFuelTank"
                                 style="background-color:#2f303a"/>
                    <BInputGroupAppend>
                      <BFormInput size="sm"
                                  v-model="fuel.mid"
                                  readonly/>
                    </BInputGroupAppend>
                    <BInputGroupAppend>
                      <BFormInput size="sm"
                                  :value="oilCodeMap[fuel.ocd]"
                                  readonly/>
                    </BInputGroupAppend>

                  </BInputGroup>


                  <BInputGroup prepend="항공기 번호" size="sm" class="mt-1">
                    <BFormSelect size="sm"
                                 v-model="fuel.eqid"
                                 :options="fuelEqidOpts"
                                 @input="selectFuelEqid"
                                 style="background-color:#2f303a"/>
                  </BInputGroup>


                  <BInputGroup prepend="수령인 구분" size="sm" class="mt-1">
                    <BFormSelect size="sm"
                                 v-model="fuel.mdcd"
                                 :options="fuelMdcdOpts"
                                 style="background-color:#2f303a"/>
                  </BInputGroup>

                  <BInputGroup prepend="수령인 군번" size="sm" class="mt-1">
                    <BFormInput size="sm"
                                placeholder="00-00000000"
                                v-model="fuel.ano"
                                maxLength="15"
                    />
                  </BInputGroup>

                  <BInputGroup prepend="급유량 (리터)" size="sm" class="mt-1">
                    <BFormInput size="sm"
                                type="number"
                                v-model="fuel.outOvc"
                    />
                    <BInputGroupAppend>
                      <BFormInput size="sm"
                                  :value="`${ltr2gal(fuel.outOvc,2)} gal`"
                                  readonly/>
                    </BInputGroupAppend>
                  </BInputGroup>


                  <BInputGroup prepend="사유" size="sm" class="mt-1">
                    <BFormInput size="sm"
                                placeholder="100자 내외 필수 입력"
                                v-model="fuel.opRemark"
                                maxLength="110"
                    />
                  </BInputGroup>

                  <div class="text-right mt-2">
                    <BButtonGroup>
                      <BButton variant="warning" class="font-weight-bold mr-1"
                               @click="createFueling">
                        <BIconSave2Fill/> 급유 정보 저장
                      </BButton>

                      <BButton variant="secondary" @click="showFuelingForm">
                        <BIconArrowClockwise/> 리 셋
                      </BButton>

                      <BButton variant="dark" class="ml-3" @click="isShowFuelingForm=false">
                        <BIconXLg/> 닫기
                      </BButton>

                    </BButtonGroup>
                  </div>

                </CCardBody>



              </CCard>
            </CCol>
          </CRow>


        </BTab>
        <BTab title="항공기 소모처리">

          <Consumption/>


        </BTab>
      </BTabs>

    </BCard>



  </div>
</template>

<style src="spinkit/spinkit.min.css"></style>

<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';

import {
  alertConfirm,
  apiCall, cloneVar, ltr2gal, unitSign
} from '@/common/utils';
import qs from 'querystring';
import moment from "moment";
import con, {ExcelEditorLabel, MdcdMap} from '@/common/constants'
import GraphInout from '../components/GraphInout';
import {InoutColMap} from "@/common/schema";
import {IoOpts, IoStsMap, FuelStsOpts} from "@/common/constants";
import Consumption from "@/views/inventory/Consumption.vue";


// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };
const _fuel = {
  bgDt: null,
  edDt: null,
  tid: '',
  mid: '',
  tnm: '',
  tcd: '',
  use: '',
  arcd: '',
  dfcd: '',
  eqcd: '',
  eqid: '',
  ano: '',
  mdcd: '',
  eqnm: '',

  sts : 'R',
  ocd: '', // 유종코드
  outOvc: 0,
  adjRemark: '매뉴얼 등록',
  opRemark: '',
}

const fuelChecks = {
  bgDt: '급유 시작일자',
  edDt: '급유 종료일자',
  tid: '급유차',
  tcd: '탱크 코드',
  use: '사용형태',
  arcd: '관제지역',
  dfcd: 'DELIIS 코드',
  eqcd: '항공기 코드',
  eqid: '항공기 번호',
  ano: '수령인 군번',
  mdcd: '수령인 군구분',
  eqnm: '항공기 명',
  sts : '급유상태',
  ocd: '유종코드',
  outOvc: '급유량(리터)',
  opRemark: '등록 사유',
};

//----------------------------------------------------------------------------------------------------
export default {
  name: "Fueling",
  components: {
    Consumption,
    GraphInout
  },
  data () {
    return {
      graphKey: 0,
      volUnit: this.$store.state.volUnit,
      lenUnit: this.$store.state.lenUnit,
      tmpUnit: this.$store.state.tmpUnit,
      con,
      fromDts: moment().format('YYYY-MM-DD'),
      toDts: moment().format('YYYY-MM-DD'),
      batchDts: moment().format('YYYY-MM-DD'),
      row: null,
      editorKey: 0,
      sts: '',
      arCode: '',
      tanks: [],
      tid: '',
      cols: InoutColMap,

      IoOpts,
      FuelStsOpts,
      IoStsMap,
      tankOpts : [],
      tankCodes: this.$store.state.tanks['codes'],
      oilCodeMap: this.$store.state.codeMaps['OIL_CODE'],
      tank : null,


      editorLabel: ExcelEditorLabel,
      downloadModalShow: false,
      downloadReason: '',

      searchOpts: [
        {value:'tid',        text:'탱크아이디'},
        {value:'name',       text:'탱크명'},
        {value:'arCode',     text:'지역코드'},
      ],
      searchField: 'tid',
      searchWord : '',

      rows: [],
      selectedRows: [],
      selectedInfo: { normal:0, exclude: 0, already:0},
      paramMap: {},

      fuel: cloneVar(_fuel),
      isShowFuelingForm: false,
      fuelTankOpts : [],
      fuelTankMap : {},
      fuelMdcdOpts : [],

      fuelEqidOpts: [],
      fuelEqidMap: {},

      fuelEqcdMap: {},

    }

  },
  async created(){
    try{

      console.log("[Fueling] created ---------------------");

    }catch(err){
      console.error(err);
    }
  },

  computed: {},

  async mounted() {
    // console.log("--- Fueling mounting---------------------");
    await this.getTanks();
    await this.getInoutData();
    await this.getFuelCodes();

    this.fuelMdcdOpts = [ {value: '', text: '수령인 군구분 선택'}]
    Object.keys(MdcdMap).map(k=>{
     this.fuelMdcdOpts.push({value: k, text: MdcdMap[k] } );
    })


    // below is not work!
  },

  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners

  },

  methods: {
    ltr2gal,
    unitSign,

    async getTanks(){
      try{

        this.tank = null;
        this.tankOpts = [{value: '', text: '전체 탱크'}];
        this.fuelTankOpts = [{value: '', text: '탱크로리 선택'}];
        this.fuelTankMap = {};
        this.fuelTankOpts = [];

        const r = await apiCall('get', `/api/tank/lorry`);
        if(r.code===200){
          r.result.map(t=>{
            this.tanks.push(t);
            this.tankOpts.push({ value: t.tid, text: `[${t.tid}] ${t.name}`} );

            this.fuelTankOpts.push({ value: t.tid, text: `[${t.tid}] ${t.name}-${t.machine.mid}`} );
            this.fuelTankMap[t.tid] = t;
          })

          // await this.toastInfo(`탱크정보 ${this.tanks.length}건 조회`, 'info');

          // console.log( 'task-data-query-result--->', r.result  );
        }

      }catch(err){
        // console.log( 'getTankList----------------->', err );
        this.toastError(err);
      }

    },



    async getFuelCodes(){
      try{
        this.fuelEqidOpts = [];
        this.fuelEqcdMap =  {};
        this.fuelEqidMap = {};

        const rs = await apiCall('get', `/api/equipment/code`);
        if(rs.code===200){
          rs.result.map(e=> {
            this.fuelEqcdMap[e.eqcd] = e.name
          } );
        }

        const r = await apiCall('get', `/api/equipment/aircraft`);

        if(r.code===200){
          r.result.map(e=>{
            this.fuelEqidOpts.push({value: e.eqid, text: `[${this.fuelEqcdMap[e.eqcd]}] ${e.eqid}`})
            this.fuelEqidMap[e.eqid] = e;
          })
        }

      }catch(err){
        console.error( err );
      }

    },

    async createFueling(){

      try{
        const p = this.fuel;

        let message = '';
        Object.keys(p).map(k=>{
          if(fuelChecks[k] && !p[k]) {
            message += fuelChecks[k]+', ';
          }
        })

        if(message!=='') {
          return alert(message + '입력값을 확인 하세요');
        }

        const r = await apiCall('post', `/api/inout/fuel/${this.fuel.tid}`, this.fuel);
        if(r.code===200){
          await this.alertSuccess("급유정보 등록 성공");
          await this.getInoutData();
        }else{
          await this.toastResult(r);
        }


      }catch(err){
        console.error(err);
      }

    },



    async deleteFueling(){
      try{
        if(!this.row) return;

        if( !this.row.isDone ) alert("미완료 정보 삭제 불가");

        if (!(await alertConfirm(this.$bvModal, '삭제행는 로그에 기록되며 데이터는 되돌릴 수 없습니다. 선택 정보를 삭제 하시겠습니까? ', '급유/배유정보 삭제'))) {
          return;
        }

        const r = await apiCall('del', `/api/inout/fuel/${this.row._id}`, this.row);
        if(r.code===200){
          await this.alertSuccess("급유정보가 삭제되었습니다.");
          await this.getInoutData();
        }else{
          await this.toastResult(r);
        }
      }catch(err){
        console.error(err);
      }


    },

    showFuelingForm(){
      this.row = null;
      this.fuel = cloneVar( _fuel );
      this.fuel.bgDt = moment().format('YYYY-MM-DD')
      this.fuel.edDt = moment().format('YYYY-MM-DD')
      this.isShowFuelingForm = true;
    },

    selectFuelTank(val){
      // console.log('val --->', val);
      if(!val) return;

      const t = this.fuelTankMap[val];

      this.fuel.mid =  t.machine.mid;
      this.fuel.tnm = t.name;
      this.fuel.tcd = t.tankCode;
      this.fuel.use = t.tankUse;
      this.fuel.ocd = t.oilCode;
      this.fuel.arcd = t.arCode;
      this.fuel.dfcd = t.dfCode;

    },

    selectFuelEqid(val){
      if(!val) return;
      this.fuel.eqcd = this.fuelEqidMap[val]?.eqcd;
      this.fuel.eqnm = this.fuelEqcdMap[this.fuel.eqcd];
    },


    async getInoutData(){
      this.rows = [];
      this.row = null;
      try{
        let qry ;

        this.paramMap = {
          tid: this.tid,
          sts: this.sts,
          fromDts: this.fromDts,
          toDts: this.toDts,
          volUnit: this.volUnit,
          lenUnit: this.lenUnit,
          tmpUnit: this.tmpUnit
        };

        this.$store.state.volUnit =  this.volUnit;
        this.$store.state.lenUnit =  this.lenUnit;
        this.$store.state.tmpUnit =  this.tmpUnit;

        if(!this.volUnit) return alert('부피단위를 선택하세요');
        if(!this.lenUnit) return alert('길이단위를 선택하세요');
        if(!this.tmpUnit) return alert('온도단위를 선택하세요');


        if(this.searchWord) this.paramMap[this.searchField] = this.searchWord;
        qry = qs.stringify( this.paramMap );
        // console.log( "query-string ---------- ", qry );

        const r = await apiCall('get', `/api/inout/consumption?${qry}`);

        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`급유/배유 현황 ${this.rows.length}건 조회됨`, 'info');
          // console.log( 'task-data-query-result--->', r.result  );
        }else{
          await this.toastResult( r );
        }
        // this.$refs['excelGrid'].clearFilter();

      }catch(err){
        console.error( 'getTankList----------------->', err );
        this.toastError(err);
      }

    },



    async selectRecord(idx, evt){
      try{
        if(!evt){
          this.row = null;
          this.selectedRows = [];
          this.selectedInfo = { normal:0, exclude: 0, already:0};
          return;
        }
        this.selectedRows = this.$refs['excelGrid'].getSelectedRecords();
        this.getSelectedInfo();
        if(this.selectedRows.length===1) {
          this.row = this.selectedRows[0];
        } else{
          this.row = null;
        }
        this.graphKey++;

      }catch(err){
        console.error(err)
      }
    },


    getSelectedInfo(){
      let cnt = { normal:0, exclude:0, already:0 };
      for(const r of this.selectedRows){
        if(r.isDone && r.batchDt===null ) cnt.normal++;
        if(!r.isDone) cnt.exclude++;
        if( r.isDone && r.batchDt!==null ) cnt.already++;
      }
      this.selectedInfo = cnt;
    },

    async setConsumption(){
      try{
        for(const r of this.selectedRows){
          if(!r.isDone || r.sts==='D') {
            const idx = this.$refs['excelGrid'].rowIndex[r.$id];
            this.$refs['excelGrid'].unSelectRecord(idx);
          }
          this.selectedRows = this.$refs['excelGrid'].getSelectedRecords();
        }
        if( this.selectedRows.length === 0 ) {
          await this.alertWarn('처리할 데이터가 없습니다.');
          return false;
        }

        const yn = await this.confirmModal(
          `총 ${this.selectedRows.length}개의 데이터카 소모처리자료로 등록 됩니다. 진행 하시게습니까?`,
          '항공기 소모처리 자료 등록 확인' );

        if(!yn) {
          await this.alertInfo('소모처리 자료 등록 취소');
          return false;
        }

        const ids = [];
        for( const r of this.selectedRows){
          ids.push(r._id);
        }

        const rs = await apiCall("POST", `/api/consumption`, {ids});
        if(rs.code===200){
          await this.alertSuccess("소모처리 자료 등록완료: "+rs.result.count+"건");
          this.selectedInfo = { normal:0, exclude: 0, already:0};
        }else{
          await this.toastResult(rs);
        }
        // console.log(rs);

      }catch(err){
        console.error(err);
      }
    },


    async updateCell(rec){

      let rc = rec.pop();
      if(!rc.keys[0]){
        return;
      }

      // console.log( "updateRecord--------- record.$id--->",rc.$id );
      // console.log( "updateRecord--------- record.keys[0]--->",rc.keys[0] );
      // console.log( "updateRecord--------- rec[0]|rc --->",rc );
      const idx = this.$refs['excelGrid'].rowIndex[rc.$id];
      // console.log( "updateRecord--------- rowIndex--->",idx );
      // console.log( "updateRecord--------- $refs --->", this.$refs['excelGrid'] );

      // let row = this.$refs['excelGrid'].table[tIndex];
      //
      // console.log( "row ---------> ", row );

      const cols = this.cols;
      let r = null;

      const param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal;
      try{
        // console.log( 'old value --->',rc.oldVal, 'new value --->', rc.newVal );
        // console.log( '-----> will be updated to --->', param);
        r = await apiCall("PUT", `/api/inout/${objectId}`, param);
        // console.log( r );
        if( r.code===200 ) {
          this.rows[idx] = r.result;
          this.editorKey++;
        }

        // console.log( r );
        await this.toastResult(r, `${cols[fieldName]} 수정`);
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.error( err );
      }

    },

  }
}
</script>
